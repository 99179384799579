// Hooks
import React, { useEffect } from "react";

// Components
import Toast from "../../../Shared/toast/toast";
import ServiceInfoCards from "./service-info-cards/service-info-cards";
import ServicesPopup from "./services-popup/servicesPopup";
import ApprovalBox from "./approval-box/approvalBox";

// Style
import Styles from "./trial-center.module.scss";

// Functions
import { callApi } from "../../../api/fetch";
import { customEvent } from "../../../utils/ga4";

function TrialCenter() {
  const forbiddenServices = [
    "digilocker",
    // "faceliveness",
    // "aadhaarbasede-sign",
    "ocrsdk",
    "studentverificationsdk",
    "cinlite",
    "panmini",
    "rcfinder",
    // "aadhaarverification",
    "rcchassis",
    "rcreverseinvoice",
    "namematchapi",
    "rc-miniapi",
    // "pandemographic",
    "aadhaarpro",
    "merchantreports",
    "fastagadvance",
    "facesearchapi",
    "challanpayment",
    "rcverificationpan"
  ];

  const [showServicesPopup, setShowServicesPopup] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState({});
  const [intervalId, setIntervalId] = React.useState<any>("id");
  const [subscribedServices, setSubscribedServices] = React.useState<any>([]);
  const [pendingServices, setPendingServices] = React.useState<any>([]);
  const [toast, setToast] = React.useState({
    message: "",
    type: "",
    show: false,
  });
  const org_id: any = localStorage.getItem("organisation_id");
  const user: any = localStorage.getItem("@user");
  const x_user_id = JSON.parse(user)._id;
  const [appId, setAppId] = React.useState("");

  function checkHeaders(checks: string[]) {
    if (
      checks.includes("userID") &&
      (x_user_id === "" || x_user_id === undefined || x_user_id === null)
    ) {
      throw new Error(
        "Unable to retrieve verification results. Please try again later!"
      );
    }
    if (
      checks.includes("orgID") &&
      (org_id === "" || org_id === undefined || org_id === null)
    ) {
      throw new Error(
        "Unable to retrieve verification results. Please try again later!"
      );
    }
    if (
      checks.includes("appID") &&
      (appId === "" || appId === undefined || appId === null)
    ) {
      throw new Error(
        "Unable to retrieve verification results. Please try again later!"
      );
    }
  }

  async function getApp() {
    try {
      checkHeaders(["userID"]);
      const response = await callApi("/app?page=trial-center", {
        method: "GET",
        init: {
          headers: {
            "x-user-id": x_user_id,
          },
        },
      });
      const applicationID =
        response.filter(
          (app: any) => app.name.replaceAll(" ", "").toLowerCase() === "testapp"
        )[0]._id ?? "";

      if (
        applicationID === "" ||
        applicationID === undefined ||
        applicationID === null
      ) {
        throw new Error(
          "Unable to retrieve verification results. Please try again later!"
        );
      }
      setAppId(applicationID);
    } catch (e) {
      toastTimeout(
        true,
        "error",
        (e as Error).message ?? "Something went wrong"
      );
    }
  }

  function handleServicePopup(serviceInfo: Record<string, any>) {
    setShowServicesPopup(true);
    setSelectedService(serviceInfo);
    if (serviceInfo?.name) {
      customEvent(
        "Run-Trial",
        `Run-Trial-Clicked-for-${serviceInfo?.name.split(" ").join("-")}`,
        `Run-Trial-for-${serviceInfo?.name.split(" ").join("-")}`
      );
    }
  }

  async function getAllSubscribedProducts() {
    try {
      let response = await callApi(
        "/product?subscription_status=subscribed&remaining_test_credits=true",
        {
          method: "GET",
        }
      );
      response = response.filter(
        (service: any) =>
          !forbiddenServices.includes(
            service.name.replaceAll(" ", "").toLowerCase()
          )
      );
      setSubscribedServices([...response]);
    } catch (e) {
      toastTimeout(
        true,
        "error",
        (e as Error).message ?? "Something went wrong"
      );
    }
  }

  async function getAllUnsubscribedProducts() {
    try {
      const response = await callApi(
        "/product?subscription_status=pending_approval",
        {
          method: "GET",
        }
      );

      setPendingServices([...response]);
    } catch (e) {
      toastTimeout(
        true,
        "error",
        (e as Error).message ?? "Something went wrong"
      );
    }
  }

  function toastTimeout(
    showToastValue: boolean,
    toastType: string,
    toastMessage: string
  ) {
    setToast((toast: any) => ({
      ...toast,
      type: toastType,
      message: toastMessage,
      show: showToastValue,
    }));
    setTimeout(() => {
      setToast((toast: any) => ({
        ...toast,
        show: !showToastValue,
      }));
    }, 8000);
    return;
  }

  useEffect(() => {
    getApp();
    getAllUnsubscribedProducts();
  }, []);

  useEffect(() => {
    clearInterval(intervalId);
    getAllSubscribedProducts();
  }, [showServicesPopup]);

  return (
    <div className={Styles.container}>
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          click={() =>
            setToast((toast: any) => ({
              ...toast,
              show: false,
            }))
          }
        />
      )}
      <div className="p-2 m-2">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="d-flex">
              <div className="flex-grow-1 ml-1">
                {/* <Tabs defaultActiveKey="first">
                <Tab
                  tabClassName={Styles.navTabs}
                  eventKey="first"
                  title="Trial Products"
                > */}
                <div>
                  <p className={`${Styles.headingText} ml-1 mt-4`}>
                    Products available for trial
                  </p>
                  <div className="d-flex flex-wrap">
                    {subscribedServices.map((info: any, index: number) => (
                      <ServiceInfoCards
                        uniqueId={index}
                        key={index}
                        serviceInfo={info}
                        handleServicePopup={handleServicePopup}
                        browse={false}
                      />
                    ))}
                    <ServiceInfoCards browse={true} />
                  </div>
                </div>
                {/* </Tab> */}
                {/* <Tab
                tabClassName={Styles.navTabs}
                eventKey="second"
                title="App Details"
              >
                <AppDetails />
              </Tab>
              <Tab
                tabClassName={Styles.navTabs}
                eventKey="third"
                title="App Keys"
              >
                <AppKeys />
              </Tab>
              <Tab
                tabClassName={Styles.navTabs}
                eventKey="forth"
                title="IP Whitelisting"
              >
                <IpWhitelisting />
              </Tab>
              <Tab
                tabClassName={Styles.navTabs}
                eventKey="fifth"
                title="Webhook"
              >
                <Webhook />
              </Tab> */}
                {/* </Tabs> */}
              </div>
            </div>
          </div>
          <div>
            {pendingServices.length > 0 && (
              <div className="mt-2">
                <ApprovalBox pendingServices={pendingServices} />
              </div>
            )}
          </div>
        </div>
      </div>
      {showServicesPopup && (
        <ServicesPopup
          org_id={org_id}
          x_user_id={x_user_id}
          appId={appId}
          checkHeaders={checkHeaders}
          toastTimeout={toastTimeout}
          onCancel={() => setShowServicesPopup(false)}
          selectedService={selectedService}
          setIntervalId={setIntervalId}
        />
      )}
    </div>
  );
}

export default TrialCenter;
