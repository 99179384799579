import React from "react";

// Functions
import { callApi } from "../../../../api/fetch";
import { customEvent } from "../../../../utils/ga4";

// styles
import Styles from "./servicesPopup.module.scss";

// Components
import ResponseBox from "./response-box/responseBox";
import PassportServicePopup from "./passport-service-popup/passportServicePopup";
import PassportAdvanceServicePopup from "./passport-service-popup/passportAdvanceServicePopup";
import PanServicePopup from "./pan-service-popup/panServicePopup";
import GstinPopup from "./gstin-popup/gstinPopup";
import BankAccountVerificationPopup from "./bank-account-verification-popup/bankAccountVerificationPopup";
import DlServicePopup from "./dl-popup/dlPopup";
import VoterServicePopup from "./voter-service-popup/voterServicePopup";
import IfscServicePopup from "./ifsc-popup/ifscPopup";
import OcrServicePopup from "./ocr-service-popup/ocrServicePopup";
import RcPopup from "./rc-popup/rcPopup";
import FaceCropOrMatch from "./face-crop-or-match-popup/faceCropOrMatch";
import OkycServicePopup from "./okyc-service-popup/okycServicePopup";
import ChequeOcrPopup from "./cheque-ocr-service-popup/chequeOcrServicePopup";
import TelecomServicePopup from "./telecom-service/telecomServicePopup";
import AadhaarServicePopup from "./aadhaar-verification/aadhaarVerification";
import UdyogServicePopup from "./udyog-service-popup/udyogServicePopup";
import CinServicePopup from "./cin-service-popup/cinServicePopup";
import FssaiServicePopup from "./fssai-service-popup/fssaiServicePopup";
import EPFOServicePopup from "./epfo-service-popup/epfoServicePopup";
import ESignPopup from "./esign-popup/esignPopup";
import EmailServicePopup from "./email-verification-api/emailVerificationApiPopUp";
import MerchantServicePopup from "./merchant-report-popup/merchantReportPopup";

import RcFinancePopup from "./rc-popup/rcFinancePopup";
import { IdsGenerator } from "../../../../utils/idGenerator";

// Types
import RcSimilaritiesPopup from "./rc-popup/rcSimilarities";
import {
  bavServiceFields,
  chequeOcrServiceFields,
  dlServiceFields,
  faceMatchService,
  gstServiceFields,
  ocrServiceFields,
  okycServiceFields,
  panServiceFields,
  passportServiceFields,
  rcServiceFields,
  rcTheftServiceFields,
  eStampApiFields,
  gstContactFields,
  voterServiceFields,
  telecomServiceFields,
  aadhaarServiceFields,
  udyogServiceFields,
  cinServiceFields,
  fssaiServiceFields,
  aadhaarProFeilds,
  epfoServiceFields,
  esignInitRequestFeilds,
  merchantReportFeilds,
  emailVerificationServiceFeilds,
  nameLookupServiceFields,
  upiVerificationServiceFields,
  tanServiceFields,
  cykcServiceFileds,
  corporateVerificationFeilds,
  esicAdvanceFields,
  mobileOtpServiceFields,
  bavAdvanceServiceFields,
  rcVechServiceFeilds,
  rcContactServiceFeilds,
  documentProcessorFields,
  rcFinanceFeilds,
  rcValuationFields,
  rcSimilarities,
  dinServiceFields,
  panToTanFields,
  tanToPanFields,
  esicLiteFields,
} from "../Shared/interfaces";
import NameLookupPopup from "./name-lookup/nameLookupPopup";
import TanServicePopup from "./tan-service-popup/tanServicePopup";
import MobileLookupPopup from "./mobile-service-popup/mobileServicePopup";
import MobileOtpPopup from "./mobile-service-popup/mobileOtpPopup";
import CorporateVerificationServicePopup from "./corporate-verification-popup/corporateVerificationServicePopup";
import EsicAdvancePopup from "./esic-advance-popup/esicAdvancePopup";

import CkycServiceLitePopup from "./ckyc-service-popup/ckycServicePopup";
import CkycServiceAdvancePopup from "./ckyc-service-popup/ckyc-advance-servicePopup";
import AadhaarProPopup from "./aadhaar-verification/aadhaarPopup";
import GstContact from "./gst-contact-verification/gstContactPopup";
import UpiVerificationPopup from "./upi-verification/upiVerificationPopup";
import BankAccountVerificationAdvancePopup from "./bank-account-verification-popup/bankAccountVerificationAdvancePopup";
import FaceLivenessPopup from "./face-liveness/faceLivenessPopup";
import RCVechPopup from "./rc-popup/rcVechClassPopup";
import RCReversePopup from "./rc-popup/rcReversePopup";
import EstampApiPopup from "./estamp-api-service-popup/estampApiServicePopup";
import RcContactPopup from "./rc-popup/rcContactPopup";
import DocumentProcessorPopup from "./document-processor/documentProcessorPopup";
import UANAdvancePopup from "./uan-advance-popup/uanAdvancePopup";
import DigitalAgeFraudPopup from "./digital-age-fraud-popup/digitalAgeFraudPopup";
import RcValuationPopup from "./rc-popup/rcValuationPopup";
import GSTINMobilePopup from "./gstin-mobile-popup/GSTINMobilePopup";
import DinLitePopup from "./din-lite-popup/dinLitePopup";
import EsicLitePopup from "./esic-lite-popup/esicLitePopup";
import PanToTanPopup from "./pan-service-popup/panToTanPopup";
import TanToPanPopup from "./tan-service-popup/tanToPanPopup";
import UpiAdvancePopup from "./upi-verification/upiAdvancePopup";

function ServicesPopup({
  onCancel,
  selectedService,
  toastTimeout,
  org_id,
  x_user_id,
  appId,
  checkHeaders,
  setIntervalId,
}: any) {
  const serviceName = selectedService.name.replaceAll(" ", "").toLowerCase();
  const [responseBox, setResponseBox] = React.useState(false);
  const [region, category, service, type] = selectedService.arn.split("_");
  const [apiResponse, setApiResponse] = React.useState("");
  const [errorResponse, setErrorResponse] = React.useState<any>("");
  const [requestDataForOkyc, setRequestDataForOkyc] = React.useState<any>();
  const [requestDataForMobileOtp, setRequestDataForMobileOtp] = React.useState<
    any
  >();
  const [requestEmailData, setRequestEmailData] = React.useState<any>();
  const catchErrorMessage =
    "Unable to fetch verification results, please try again later";

  async function handleEsicLite(data: esicLiteFields, setLoader: any) {
    try {
      setLoader(true);
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_mobile_number: data.customerMobileNumber,
            consent: data.consent,
            consent_text:
              "I hear by declare my consent agreement for fetching my information via ZOOP API.",
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
    }
  }

  async function handleTanToPan(data: tanToPanFields, setLoader: any) {
    try {
      setLoader(true);
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_tan_number: data.customerTanNumber,
            consent: data.consent,
            consent_text:
              "I hearby agree, to let zoop.one verify my data for verification",
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleEsicAdvance(data: esicAdvanceFields, setLoader: any) {
    try {
      setLoader(true);
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_mobile_number: data.customerMobileNumber,
            consent: data.consent,
            consent_text:
              "I hear by declare my consent agreement for fetching my information via ZOOP API.",
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
    }
  }

  async function handlePanToTan(data: panToTanFields, setLoader: any) {
    try {
      setLoader(true);
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_pan_number: data.customerPanNumber,
            consent: data.consent,
            consent_text:
              "I hearby agree, to let zoop.one verify my data for verification",
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleDigitalAgeFraud(data: any, setLoader: any) {
    try {
      setLoader(true);
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            ...data,
            consent_text:
              "I hearby agree, to let zoop.one verify my data for verification",
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handlecsimilarities(data: rcSimilarities, setLoader: any) {
    try {
      setLoader(true);
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            vehicle_maker_description: data.vehicleMakerDesc,
            vehicle_make_model: data.vehicleModel,
            vehicle_cubic_capacity: data.vehicleCubicCapacity,
            vehicle_fuel_description: data.vehicleFuel,
            vehicle_seating_capacity: data.vehicleSeatingCapacity,
            rc_insurance_comp: data.vehicleInsuranceCompany,
            vehicle_gross_weight: data.vehicleGrossWeight,
            vehicle_class_description: data.vehicleClass,
            rc_permit_number: data.vehicleRcPermit,
            rc_permit_issued_date: data.vehicleRcPermitIssued,
            rc_permit_start_date: data.vehicleRcPermitStartDate,
            rc_permit_expiry_date: data.vehicleRcPermitExpiracyDate,
            rc_permit_type: data.vehicleRcPermitType,
            body_type_description: data.vehicleBodyType,
            vehicle_manufactured_date: data.vehicleManifacturingMonthAndYear,
            consent: "Y",
            consent_text: "RC Familiarities API data is verified by author",
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleGSTINMobile(data: any, setLoader: any) {
    try {
      setLoader(true);
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            business_mobile_number: data.gstMobileNumber,
            consent: data.consent,
            consent_text:
              "I hear by declare my consent agreement for fetching my information via ZOOP API.",
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleUPIAdvance(data: any, setLoader: any) {
    try {
      setLoader(true);
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_mobile_number: data.mobileNumber,
            consent: data.consent,
            consent_text:
              "I hear by declare my consent agreement for fetching my information via ZOOP API.",
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleUANAdvance(data: any, setLoader: any) {
    try {
      setLoader(true);
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_uan_number: data.uanNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handlePassportService(
    data: passportServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      let requestData = {};
      if (data.customerFileNumber) {
        requestData = {
          customer_file_number: data.customerFileNumber,
          customer_dob: data.dob,
          name_to_match: data.nameToMatch,
        };
      } else {
        requestData = {
          customer_first_name: data.firstName,
          customer_last_name: data.lastName,
          customer_dob: data.dob,
          passport_expiry_date: data.expiryDate,
          customer_country: data.country,
          customer_gender: data.gender,
          customer_passport_number: data.passportNumber,
          passport_type: data.type,
          customer_nationality: data.country,
        };
      }

      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            ...requestData,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleEstampApiService(data: eStampApiFields, setLoader: any) {
    try {
      const { consent, ...requestData } = data;
      const apiKey = "g7ew9f-ew435-435"; // random
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            test: "true",
            "api-key": apiKey,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            ...requestData,
            consent: consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handlePanService(data: panServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_pan_number: data.panNumber,
            customer_dob: data.dob,
            user_name: data.userName,
            consent: data.consent,
            pan_holder_name: data.panHolderName,
            pan_details: data.panDetails,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleRcValuationService(
    data: rcValuationFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            vehicle_registration_number: data.vehicle_registration_number,
            total_distance_driven: data.total_distance_driven,
            consent: data.consent,
            consent_text: "RC Valuation is Verified by author",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handlercContactService(
    data: rcContactServiceFeilds,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            mobile_number: data.phoneNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleRcvehicleService(
    data: rcVechServiceFeilds,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            vehicle_registration_number: data.vehicle_registration_number,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleGstService(data: gstServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            ...(serviceName === "gstunregistered"
              ? {
                  temporary_unregistration_number: data.gstNumber.toUpperCase(),
                }
              : {
                  business_gstin_number: data.gstNumber.toUpperCase(),
                }),
            ...(serviceName === "gstverificationadvance" && {
              financial_year: data.financialYear,
            }),
            ...(serviceName === "gstverificationadvance" && {
              contact_info: data.contactInfo,
            }),
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleGstPanService(data: gstServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            business_pan_number: data.gstNumber.toUpperCase(),
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleBavAdvanceService(
    data: bavAdvanceServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            account_number: data.accountNumber.toUpperCase(),
            consent: data.consent,
            consent_text: "Approve the values here",
            ifsc: data.ifscNumber,
            is_penny_drop: data.is_penny_drop,
            name_to_match: data?.name_to_match,
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleAadhaarProService(
    data: aadhaarProFeilds,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_aadhaar_number: data.customer_aadhaar_number,
            consent: data.consent,
            consent_text: "Approve the values here",
            pan_details: data.pan_details,
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleCkycLiteService(
    data: cykcServiceFileds,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_pan_number: data.customerPanNumber,
            customer_dob: data.customer_dob,
            document_type: data.document_type,
            customer_aadhaar_number: data.customer_aadhaar_number,
            customer_name: data.customer_name,
            customer_gender: data.customer_gender,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleCkycAdvanceService(
    data: cykcServiceFileds,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_pan_number: data.customerPanNumber,
            customer_dob: data.customer_dob,
            document_type: data.document_type,
            customer_aadhaar_number: data.customer_aadhaar_number,
            customer_name: data.customer_name,
            customer_gender: data.customer_gender,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleRcTheft(data: rcTheftServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            vehicle_registration_number: data.vehicleRegNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleRcService(data: rcServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            vehicle_registration_number: data.vehicleRegNumber,
            challan: true,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleRcChallanService(data: rcServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            vehicle_registration_number: data.vehicleRegNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleRcFinanceService(data: rcFinanceFeilds, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            vehicle_registration_number: data.vehicle_registration_number,
            kilometer: data.kilometer,
            consent: data.consent,
            consent_text: "RC Advance is Verified by author",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleGstContactService(
    data: gstContactFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            business_gstin_number: data.business_gstin_number,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleRcReverseService(data: rcServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            chassis_number: data.chassisNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleRcRtoService(data: rcServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            vehicle_registration_number: data.vehicleRegNumber,
            rc_chassis_number: data.chassisNumber,
            rc_tax_mode: data.rcTaxMode,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleBavService(data: bavServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            account_number: data.accountNumber,
            ifsc: data.ifscNumber,
            name_to_match: data.name_to_match,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleVoterService(data: voterServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_epic_number: data.epicNumber,
            consent: data.consent,
            name_to_match: data.nameToMatch,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleDlService(data: dlServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_dl_number: data.dlNumber,
            customer_dob: data.dob,
            consent: data.consent,
            name_to_match: data.nameToMatch,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleIfscService(data: bavServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            ifsc: data.ifscNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleOcrService(data: ocrServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            card_front_image: data.finalFront.toString(),
            card_back_image: data.finalBack.toString(),
            card_type: data.cardType,
            aadhaar_masked_image:
              data.cardType === "AADHAAR_MASKED"
                ? data?.aadhaar_masked_image
                : undefined,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function getOtpForOkyc(aadharNumber: string, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category: "identity",
          service: "aadhaar",
          type: "okyc",
          action: "request",
          mode: "sync",
          data: {
            customer_aadhaar_number: aadharNumber,
            consent: "Y",
            consent_text: "Approve the values here",
          },
        },
      });
      setRequestDataForOkyc({ ...response });
      setApiResponse(JSON.stringify(response));
      setLoader(false);
      setResponseBox(true);
      return response;
    } catch (e) {
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
    }
  }

  async function handleOkycService(data: okycServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category: "identity",
          // service: "okyc",
          // type: "verifyotp",
          // action: "test",
          service: "aadhaar",
          type: "okyc",
          action: "verify",
          mode: "sync",
          data: {
            request_id: requestDataForOkyc.request_id,
            otp: data.otp,
            consent: data.consent,
            name_to_match: data.nameToMatch,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function faceCropOrMatchService(
    data: faceMatchService,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            card_image: data.finalCardImage,
            user_image: data.finalUserImage,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleChequeOcrService(
    data: chequeOcrServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            cheque_image: data.finalChequeImage,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleTelecomAPIService(
    data: telecomServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            mobile_number: data.phoneNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleDinLiteVerification(
    data: dinServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_din_number: data.customerDinNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleAadhaarVerification(
    data: aadhaarServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_aadhaar_number: data.aadhaarNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleUdyogVerification(
    data: udyogServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            udyog_aadhaar: data.udyogAadhaarNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleCinNumberVerification(
    data: cinServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            cin_number: data.cinNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleNameLookupService(
    data: nameLookupServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            mobile_number: data.phoneNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleUpiVerificationService(
    data: upiVerificationServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_upi_id: data.upiId,
            consent: data.consent,
            consent_text: "Here i declare above information is correct!.",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleMobileLookupService(
    data: nameLookupServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            mobile_number: data.phoneNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function getOtpForMobileOtp(mobileNumber: string, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          action: "request",
          mode: "sync",
          data: {
            mobile_number: mobileNumber,
            consent: "Y",
            consent_text: "Approve the values here",
          },
        },
      });
      setRequestDataForMobileOtp({ ...response });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
      return response;
    } catch (e) {
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleMobileOtpService(
    data: mobileOtpServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          action: "verify",
          mode: "sync",
          data: {
            otp: data.otp,
            request_id: requestDataForMobileOtp.request_id,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleFssaiNumberVerification(
    data: fssaiServiceFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            fssai_number: data.fssaiNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleEpfoService(data: epfoServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_phone_number: data.phoneNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleCorporateVerificationService(
    data: corporateVerificationFeilds,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_phone_number: data.phoneNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleESignInitRequest(
    data: esignInitRequestFeilds,
    setLoader: any
  ) {
    try {
      checkHeaders(["orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            ...data,
            // consent: data.consent,
            // consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
      //  TO open new Tab with request ID in esign url
      if (response.success === true) {
        window.open(
          `${process.env.REACT_APP_ESIGN_SIGN_URL}` +
            response.requests[0].request_id +
            "?show_download_btn=Y&mode=REDIRECT&zoom_level=0.8&v=4.2.0",
          "_blank",
          "noreferrer"
        );
      }
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function getOtpForEmailVerification(
    Email: string,
    setLoader: any,
    Name: string
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category: "persona",
          service,
          type,
          mode: "sync",
          action: "request-test",
          data: {
            email: Email,
            consent: "Y",
            consent_text: "Approve the values here",
            name: Name.trim(),
          },
        },
      });

      setRequestEmailData({ ...response });
      setLoader(false);
      setApiResponse(JSON.stringify({ ...response }));
      setResponseBox(true);

      return response;
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleEmailVerficationService(
    data: emailVerificationServiceFeilds,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          action: "submit-test",
          data: {
            request_id: requestEmailData.request_id,
            otp: data.otp,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleTanVerification(data: tanServiceFields, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            customer_tan_number: data.customerTanNumber,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function handleDocumentProcessor(
    data: documentProcessorFields,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          data: {
            document_file: data.document_file,
            document_type: data.documentType,
            consent: data.consent,
            consent_text: "Approve the values here",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  //changes
  async function handleMerchantReport(
    data: merchantReportFeilds,
    setLoader: any
  ) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "x-user-id": x_user_id,
          },
        },

        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          mode: "sync",
          report_id: data.reportId,
          data: {
            customer_pan_number: data.customerPanNumber,
            business_gstin_number: data.businessGstinNumber,
            cin_number: data.cinNumber,
            consent: data.consent,
            consent_text:
              "I consent to this information being shared with zoop.one",
          },
        },
      });
      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function faceLivenessInit(data: any, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const apiKey = "g7ew9f-ew435-435"; // random
      const host = window?.location?.protocol + "//" + window?.location?.host;

      const { init, ...response } = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "api-key": apiKey,
            "x-user-id": x_user_id,
            test: "true",
          },
        },
        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          action: "init",
          mode: "sync",
          data: {
            consent_text:
              "I consent to this information being shared with zoop.one",
            purpose: "To Verify Liveness",
            response_url: "https://webhook.site/sdkResponse",
            redirect_url: host + "/dashboard",
            ...data,
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);

      if (response?.result?.sdk_url && response?.result?.request_id) {
        window.open(response?.result?.sdk_url, "_blank");
      }
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
      setResponseBox(true);
    }
  }

  async function faceLivenessFetch(request_id: string, setLoader: any) {
    try {
      checkHeaders(["userID", "orgID", "appID"]);
      const apiKey = "g7ew9f-ew435-435"; // random
      const response = await callApi("/demoPortal/product", {
        method: "POST",
        init: {
          headers: {
            "app-id": appId,
            "org-id": org_id,
            "api-key": apiKey,
            "x-user-id": x_user_id,
          },
        },

        data: {
          arn: selectedService.arn,
          region,
          category,
          service,
          type,
          action: "fetch",
          mode: "sync",
          data: {
            request_id,
          },
        },
      });

      setLoader(false);
      setApiResponse(JSON.stringify(response));
      setResponseBox(true);
    } catch (e) {
      setLoader(false);
      setErrorResponse(
        typeof e === "object" ? (e as Error).message : e ? e : catchErrorMessage
      );
    }
  }

  function handleResponse(data: any, setLoader: any) {
    switch (true) {
      case serviceName === "esiclite":
        handleEsicLite(data, setLoader);
        break;
      case serviceName === "tantopanverification":
        handleTanToPan(data, setLoader);
        break;

      case serviceName === "pantotan":
        handlePanToTan(data, setLoader);
        break;

      case serviceName === "digitalage":
        handleDigitalAgeFraud(data, setLoader);
        break;

      case serviceName === "businessmobiletogstin":
        handleGSTINMobile(data, setLoader);
        break;

      case serviceName === "rcsimilarities":
        handlecsimilarities(data, setLoader);
        break;

      case serviceName === "upiadvance":
        handleUPIAdvance(data, setLoader);
        break;

      case serviceName === "uanadvance":
        handleUANAdvance(data, setLoader);
        break;

      case serviceName === "faceliveness":
        faceLivenessInit(data, setLoader);
        break;

      case serviceName === "passportlite" || serviceName === "passportadvance":
        handlePassportService(data, setLoader);
        break;

      case serviceName === "estampapi":
        handleEstampApiService(data, setLoader);
        break;

      case serviceName === "esicadvance":
        handleEsicAdvance(data, setLoader);
        break;

      case serviceName === "panlite" ||
        serviceName === "panadvance" ||
        serviceName === "pandemographic" ||
        serviceName === "pan206ab" ||
        serviceName === "panmicro" ||
        serviceName === "panpro":
        handlePanService(data, setLoader);
        break;

      case serviceName === "gstverificationlite" ||
        serviceName === "gstverificationadvance" ||
        serviceName === "gstunregistered" ||
        serviceName === "gstaddon":
        handleGstService(data, setLoader);
        break;

      case serviceName === "gstpan":
        handleGstPanService(data, setLoader);
        break;

      case serviceName === "rcverificationlite" ||
        serviceName === "rcverificationadvance" ||
        serviceName === "rcverificationcustom" ||
        serviceName === "rcverificationdynamic" ||
        serviceName === "rcfastag" ||
        serviceName === "rc-utilityapi":
        handleRcService(data, setLoader);
        break;

      case serviceName === "rctheft":
        handleRcTheft(data, setLoader);
        break;

      case serviceName === "rcchallan":
        handleRcChallanService(data, setLoader);
        break;

      case serviceName === "rcfinance":
        handleRcFinanceService(data, setLoader);
        break;

      case serviceName === "rcreverse":
        handleRcReverseService(data, setLoader);
        break;

      case serviceName === "rcrtovehicleverification":
        handleRcRtoService(data, setLoader);
        break;

      case serviceName === "banka/cverificationlite":
        handleBavService(data, setLoader);
        break;

      case serviceName === "voteridadvance":
        handleVoterService(data, setLoader);
        break;

      case serviceName === "drivinglicenseadvance":
        handleDlService(data, setLoader);
        break;

      case serviceName === "ifscverificationlite":
        handleIfscService(data, setLoader);
        break;

      case serviceName === "ocrlite":
        handleOcrService(data, setLoader);
        break;

      case serviceName === "okyc":
        handleOkycService(data, setLoader);
        break;

      case serviceName === "facecrop" || serviceName === "facematch":
        faceCropOrMatchService(data, setLoader);
        break;

      case serviceName === "chequeocr":
        handleChequeOcrService(data, setLoader);
        break;

      case serviceName === "telecomcircleapi":
        handleTelecomAPIService(data, setLoader);
        break;

      case serviceName === "aadhaarverification":
        handleAadhaarVerification(data, setLoader);
        break;

      case serviceName === "rcvehicleclass":
        handleRcvehicleService(data, setLoader);
        break;

      case serviceName === "udyogaadhaar":
        handleUdyogVerification(data, setLoader);
        break;

      case serviceName === "cinlite" || serviceName === "cinadvance":
        handleCinNumberVerification(data, setLoader);
        break;

      case serviceName === "dinlite":
        handleDinLiteVerification(data, setLoader);
        break;

      case serviceName === "rcvaluation":
        handleRcValuationService(data, setLoader);
        break;

      case serviceName === "fssaiapi":
        handleFssaiNumberVerification(data, setLoader);
        break;

      case serviceName === "mobilelookup":
        handleMobileLookupService(data, setLoader);
        break;

      case serviceName === "mobileotp":
        handleMobileOtpService(data, setLoader);
        break;

      case serviceName === "epfopro":
        handleEpfoService(data, setLoader);
        break;
      case serviceName === "rccontact":
        handlercContactService(data, setLoader);
        break;
      case serviceName === "aadhaarbasede-sign":
        handleESignInitRequest(data, setLoader);
        break;
      case serviceName === "tanlite" || serviceName === "tanadvance":
        handleTanVerification(data, setLoader);
        break;
      case serviceName === "email-verificationapi":
        handleEmailVerficationService(data, setLoader);
        break;
      case serviceName === "gstcontact":
        handleGstContactService(data, setLoader);
        break;
      case serviceName === "bankaccountverificationadvance":
        handleBavAdvanceService(data, setLoader);
        break;
      case serviceName === "namelookup":
        handleNameLookupService(data, setLoader);
        break;
      case serviceName === "upiverification":
        handleUpiVerificationService(data, setLoader);
        break;
      case serviceName === "corporateverification":
        handleCorporateVerificationService(data, setLoader);
        break;
      case serviceName === "intelligentdocumentprocessor":
        handleDocumentProcessor(data, setLoader);
        break;
      case serviceName === "aadhaarpro":
        handleAadhaarProService(data, setLoader);
        break;
      case serviceName === "ckycadvance":
        handleCkycAdvanceService(data, setLoader);
        break;
      case serviceName === "merchantreports":
        handleMerchantReport(data, setLoader);
        break;
      case serviceName === "ckyclite":
        handleCkycLiteService(data, setLoader);
        break;
    }
    customEvent(
      "Run-Verification-Trial-Center",
      `Run-Verification-Trial-Center-Clicked-for-${serviceName}`,
      `Run-Verification-for-${serviceName}`
    );
  }

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${Styles.overlay}`}
    >
      <div className={`p-4 d-flex ${Styles.popUpContent}`}>
        <div className="d-flex">
          {serviceName === "tantopanverification" && (
            <TanToPanPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "pantotan" && (
            <PanToTanPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "digitalage" && (
            <DigitalAgeFraudPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "businessmobiletogstin" && (
            <GSTINMobilePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "upiadvance" && (
            <UpiAdvancePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "uanadvance" && (
            <UANAdvancePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "faceliveness" && (
            <FaceLivenessPopup
              name={selectedService.name}
              handleResponse={handleResponse}
              faceLivenessFetch={faceLivenessFetch}
              apiResponse={apiResponse}
            />
          )}
          {serviceName === "passportlite" && (
            <PassportServicePopup
              toastTimeout={toastTimeout}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "passportadvance" && (
            <PassportAdvanceServicePopup
              toastTimeout={toastTimeout}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "estampapi" && (
            <EstampApiPopup
              toastTimeout={toastTimeout}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {(serviceName === "panlite" ||
            serviceName === "panadvance" ||
            serviceName === "pandemographic" ||
            serviceName === "pan206ab" ||
            serviceName === "panmicro" ||
            serviceName === "panpro") && (
            <PanServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {(serviceName === "gstverificationlite" ||
            serviceName === "gstverificationadvance" ||
            serviceName === "gstunregistered" ||
            serviceName === "gstpan" ||
            serviceName === "gstaddon") && (
            <GstinPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {(serviceName === "rcverificationlite" ||
            serviceName === "rcverificationadvance" ||
            serviceName === "rcverificationcustom" ||
            serviceName === "rcverificationdynamic" ||
            serviceName === "rc-utilityapi" ||
            serviceName === "rcfastag" ||
            serviceName === "rcchallan" ||
            serviceName === "rcrtovehicleverification" ||
            serviceName === "rctheft") && (
            <RcPopup
              toastTimeout={toastTimeout}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "rcsimilarities" && (
            <RcSimilaritiesPopup
              toastTimeout={toastTimeout}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "rcreverse" && (
            <RCReversePopup
              toastTimeout={toastTimeout}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "rcfinance" && (
            <RcFinancePopup
              toastTimeout={toastTimeout}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "banka/cverificationlite" && (
            <BankAccountVerificationPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "drivinglicenseadvance" && (
            <DlServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "voteridadvance" && (
            <VoterServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "rcvehicleclass" && (
            <RCVechPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "ifscverificationlite" && (
            <IfscServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "ocrlite" && (
            <OcrServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "okyc" && (
            <OkycServicePopup
              getOtp={getOtpForOkyc}
              appData={{ appId, org_id, x_user_id }}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "mobileotp" && (
            <MobileOtpPopup
              getOtp={getOtpForMobileOtp}
              appData={{ appId, org_id, x_user_id }}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "rcvaluation" && (
            <RcValuationPopup
              toastTimeout={toastTimeout}
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {(serviceName === "facematch" || serviceName === "facecrop") && (
            <FaceCropOrMatch
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "rccontact" && (
            <RcContactPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "chequeocr" && (
            <ChequeOcrPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "telecomcircleapi" && (
            <TelecomServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "dinlite" && (
            <DinLitePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "esiclite" && (
            <EsicLitePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "aadhaarverification" && (
            <AadhaarServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

            {serviceName === "esicadvance" && (
              <EsicAdvancePopup
                name={selectedService.name}
                handleResponse={handleResponse}
              />
            )}

          {serviceName === "udyogaadhaar" && (
            <UdyogServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {(serviceName === "cinlite" || serviceName === "cinadvance") && (
            <CinServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "gstcontact" && (
            <GstContact
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "intelligentdocumentprocessor" && (
            <DocumentProcessorPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "fssaiapi" && (
            <FssaiServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "epfopro" && (
            <EPFOServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "aadhaarbasede-sign" && (
            <ESignPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {(serviceName === "tanlite" || serviceName === "tanadvance") && (
            <TanServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "mobilelookup" && (
            <MobileLookupPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "email-verificationapi" && (
            <EmailServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
              getOtp={getOtpForEmailVerification}
            />
          )}
          {serviceName === "merchantreports" && (
            <MerchantServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "corporateverification" && (
            <CorporateVerificationServicePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "aadhaarpro" && (
            <AadhaarProPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "namelookup" && (
            <NameLookupPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "upiverification" && (
            <UpiVerificationPopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          {serviceName === "bankaccountverificationadvance" && (
            <BankAccountVerificationAdvancePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "ckyclite" && (
            <CkycServiceLitePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}
          {serviceName === "ckycadvance" && (
            <CkycServiceAdvancePopup
              name={selectedService.name}
              handleResponse={handleResponse}
            />
          )}

          <section>
            {responseBox && (
              <ResponseBox
                toastTimeout={toastTimeout}
                apiResponse={apiResponse}
                // apiResponse={apiResponses}
                errorResponse={errorResponse}
              />
            )}
          </section>
        </div>
        <div>
          <button
            className={`${Styles.cancelButton}`}
            id={IdsGenerator({
              prefix: "service popup",
              id: "click",
              sufix: "close",
            })}
            onClick={onCancel}
          >
            X
          </button>
        </div>
      </div>
    </div>
  );
}

export default ServicesPopup;
